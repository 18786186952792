import React from 'react';
import { useMQTTClient } from './useMQTTClient';
import { createDeviceClientIdPrefix, generateUniqueStringId, getEnvPrefix } from 'common';
import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { parseEventMessage, parsePresenceMessage } from './utils';
import { useMyOrganization } from '../components/Organizations/useMyOrganization';
import { getEnvs } from '../utils/getEnvs';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:RealTimeListenerProvider');

type Callback<T> = (args: T) => void;

export interface RealTimeListener {
  subscribeToEvents: (deviceId: number, callback: Callback<mqtt5.MessageReceivedEvent>) => Callback<void> | null;
  subscribeToConnections: (deviceId: number, callback: Callback<boolean>) => Callback<void> | null;
}

export const RealTimeListenerContext = React.createContext<RealTimeListener | null>(null);

interface Props {
  children: React.ReactNode;
}

/**
 * If you subscribe to a topic that is included and it's less specific than another
 * FI: /states/1 and states/#
 * The less specific is not ran. So, this implementation is a workaround of that issue
 * A global listener subscribed to event notifications for the organization.
 * This section exposes the necessary methods to subscribe to these events
 * The callback list is saved in a ref to allow the handler in the effect to access them
 * without having to rerun the subscription/effect every rerender.
 */

// this maps contain an array
type ConnectionCallbackRef = Map<number, Array<{ id: string; callback: Callback<boolean> }>>;
type EventCallbackRef = Map<number, Array<{ id: string; callback: Callback<mqtt5.MessageReceivedEvent> }>>;

export const RealTimeListenerProvider: React.FC<Props> = (props) => {
  const connectionCallbacks = React.useRef<ConnectionCallbackRef>(new Map());
  const eventCallbacks = React.useRef<EventCallbackRef>(new Map());

  const { subscribe: mqttSubscribe, isConnected } = useMQTTClient();

  const { env } = getEnvs();
  const { data: organization } = useMyOrganization<true>();
  const deviceClientIdPrefix = createDeviceClientIdPrefix(env);

  const connectionTopics = React.useMemo(
    () =>
      organization.Device.map((device) => {
        const clientId = `${deviceClientIdPrefix}_${device.id}`;
        debug(`listen connections for: ${clientId}`);

        return `$aws/events/presence/+/${clientId}`;
      }),
    [organization, deviceClientIdPrefix]
  );

  const eventsTopic = React.useMemo(() => {
    const prefix = getEnvPrefix(env);

    return `${prefix}/event/${organization.id}/#`;
  }, [organization.id, env]);

  /**
   * Subscribe to connection events
   */
  const subscribeToConnections = React.useCallback((deviceId: number, callback: Callback<boolean>) => {
    const id = generateUniqueStringId();
    const currentCb = connectionCallbacks.current.get(deviceId) ?? [];
    currentCb.push({ id, callback });
    connectionCallbacks.current.set(deviceId, currentCb);

    // clean cbs
    return () => {
      debug('subscribeToConnections clean');
      const currentCb = connectionCallbacks.current.get(deviceId) ?? [];
      const newCb = currentCb.filter((item) => item.id !== id);
      connectionCallbacks.current.set(deviceId, newCb);
    };
  }, []);

  /**
   * Subscribe to specific device events
   */
  const subscribeToEvents = React.useCallback((device: number, callback: Callback<mqtt5.MessageReceivedEvent>) => {
    const id = generateUniqueStringId();
    const currentCb = eventCallbacks.current.get(device) ?? [];
    currentCb.push({ id, callback });
    eventCallbacks.current.set(device, currentCb);

    // clean cbs
    return () => {
      debug('subscribeToEvents clean');
      const currentCb = eventCallbacks.current.get(device) ?? [];
      const newCb = currentCb.filter((item) => item.id !== id);
      eventCallbacks.current.set(device, newCb);
    };
  }, []);

  /**
   * Effect for connection events
   */
  React.useEffect(() => {
    debug('effect for connection callback');
    if (!mqttSubscribe) {
      debug('null mqttSubscribe');
      return;
    }

    const handler = (eventData: mqtt5.MessageReceivedEvent): void => {
      const status = parsePresenceMessage(eventData);
      if (!status) {
        debug('Invalid presence message');
        return;
      }

      debug('connection event', { status });
      connectionCallbacks.current.forEach((callbacks, deviceId) => {
        if (status.deviceId === deviceId) {
          callbacks.forEach((item) => item.callback(status.isConnected));
        }
      });
    };

    void mqttSubscribe(connectionTopics, handler).catch((e) => debug('error subscribing to connections', e));
  }, [mqttSubscribe, connectionTopics]);

  /**
   * Effect for events
   */
  React.useEffect(() => {
    debug('effect events');
    if (!mqttSubscribe) {
      debug('null mqttSubscribe');
      return;
    }

    const handler = (eventData: mqtt5.MessageReceivedEvent): void => {
      const message = parseEventMessage(eventData);

      if (!message) {
        debug(`error parsing message`);
        return;
      }

      eventCallbacks.current.forEach((callbacks, deviceId) => {
        if (message.device === deviceId) {
          callbacks.forEach((item) => item.callback(eventData));
        }
      });
    };

    void mqttSubscribe(eventsTopic, handler).catch((e) => debug('error subscribing to events', e));
  }, [mqttSubscribe, isConnected, eventsTopic]);

  debug({
    eventCallbacks: eventCallbacks.current.size,
    connectionCallbacks: connectionCallbacks.current.size,
  });

  const value = {
    subscribeToEvents,
    subscribeToConnections,
  };

  return <RealTimeListenerContext.Provider value={value}>{props.children}</RealTimeListenerContext.Provider>;
};
