import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import * as Sentry from '@sentry/react';
import { getEnvs } from './utils/getEnvs.ts';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorPage } from './components/Error/ErrorPage.tsx';
import { RouterErrorHandler } from './components/Error/RouterErrorHandler.tsx';
import { knownBuildIssues } from './components/Error/knownBuildIssues.ts';

const envs = getEnvs();

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
    errorElement: <RouterErrorHandler />,
  },
]);

Sentry.init({
  dsn: envs.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: envs.env === 'production',
      blockAllMedia: envs.env === 'production',
    }),
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    if (event && event.extra) {
      event.extra.date = new Date().toISOString();
      event.extra.message = error;
    } else if (event) {
      event.extra = {
        date: new Date().toISOString(),
        message: error,
      };
    }

    // filter known building issues
    if (error !== null && typeof error === 'object' && 'message' in error) {
      const message = String(error.message);

      if (knownBuildIssues.some((msg) => message.includes(msg))) {
        return null;
      }
    }

    return event;
  },
  environment: envs.env,
  release: envs.release,
  enabled: envs.release !== 'local',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [envs.apiBaseURL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * When a new deployment occurs, the hosting service may delete the assets from previous deployments.
 * As a result, a user who visited your site before the new deployment might encounter an import error.
 * This error happens because the assets running on that user's device are outdated and it tries to import the corresponding old chunk, which is deleted.
 * This event is useful for addressing this situation.
 * https://vitejs.dev/guide/build#load-error-handling
 * */
window.addEventListener('vite:preloadError', (event) => {
  console.log('vite:preloadError', event);
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary showDialog fallback={<ErrorPage />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
