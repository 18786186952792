import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSession, useSessionPermissions, useSessionUser } from '../session';
import { FullWindow, SmallWindow } from '../components/Layout/Template';
import { Scenes, sitemap } from '.';
import { MQTTClientProvider } from '../broker/MQTTClientContext';
import UtilitiesScene from '../scenes/Utilities/Index';
import { Role } from 'common';
import {
  AsyncOrganization,
  AsyncBackoffice,
  AsyncAutomations,
  AsyncCultivationLogs,
  AsyncDevices,
  AsyncInvite,
  AsyncMyCultivation,
  AsyncProfile,
  AsyncSetup,
  AsyncStock,
  AsyncUnauth,
  AsyncAnalytics,
  AsyncSupport,
} from './LazyScenes';
import { useMyOrganization } from '../components/Organizations/useMyOrganization';
import { Loading } from '../components';
import { AbsoluteCenter } from '@chakra-ui/react';
import { NotFound } from './NotFound';
import { DefaultOrganizationSelector } from '../components/Layout/DefaultOrganizationSelector';
import { RealTimeListenerProvider } from '../broker/RealTimeListenerContext';
import { JobExecutionProvider } from '../components/Jobs/JobExecutionContext';
import { defineMessages } from 'react-intl';
import { TranslatedMessage } from '../i18n';
import { isIddLocationState } from '../components/Devices/utils';

const messages = defineMessages({
  selectOrganizationToContinue: {
    id: 'auth.select_org_to_continue',
    defaultMessage: 'Seleccione una organización para continuar',
  },
});

/**
 * Users can be authenticated or not.
 *
 * Authenticated users may still need to complete the setup
 * process.
 * So, the routing will depend on the status of their account
 *
 * In other case, they will be redirected
 * to the unauth scene
 */
export const RouterContainer = () => {
  const { isLoggedIn } = useSession();

  return isLoggedIn ? <AuthRouter /> : <UnauthRouter />;
};

const UnauthRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<SmallWindow />}>
        <Route path="*" element={<AsyncUnauth />} />
      </Route>
    </Routes>
  );
};

const AuthRouter = () => {
  const user = useSessionUser();
  const permissions = useSessionPermissions();
  const organizations = permissions.listOrganizations();

  if (user?.invite) {
    return (
      <Routes>
        <Route path="*" element={<FullWindow />}>
          <Route path="*" element={<Invites />} />
        </Route>
      </Routes>
    );
  }

  if (organizations.length === 0) {
    return (
      <Routes>
        <Route path="*" element={<FullWindow />}>
          <Route path="*" element={<Setup />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<FullWindow />}>
        <Route path="*" element={<Auth />} />
      </Route>
    </Routes>
  );
};

/**
 * Here we should include setup process
 *
 * This may seem confusing but using different routes allows us to restrict the navigation
 * No 403 or 404 screens
 */
const Auth: React.FC = () => {
  const permissions = useSessionPermissions();
  const user = useSessionUser();

  const { data, isLoading, error } = useMyOrganization();

  const location = useLocation();
  const idd = isIddLocationState(location.state) ? location.state.idd : null;

  if (isLoading) {
    return (
      <AbsoluteCenter>
        <Loading />
      </AbsoluteCenter>
    );
  }

  if (!data || error) {
    if (!user.defaultOrganization) {
      return (
        <AbsoluteCenter>
          <TranslatedMessage message={messages.selectOrganizationToContinue} />
          <DefaultOrganizationSelector />
        </AbsoluteCenter>
      );
    }

    return <NotFound />;
  }

  const isGlobalOwner = permissions.hasRole(Role.OWNER);
  return (
    <MQTTClientProvider>
      <RealTimeListenerProvider>
        <JobExecutionProvider>
          <Routes>
            <Route path={`${sitemap[Scenes.PROFILE].path}/*`} element={<AsyncProfile />} />
            <Route path={`${sitemap[Scenes.ORGANIZATION].path}/*`} element={<AsyncOrganization />} />
            <Route path={`${sitemap[Scenes.AUTOMATIONS].path}/*`} element={<AsyncAutomations />} />
            <Route path={`${sitemap[Scenes.CULTIVATION_LOGS].path}/*`} element={<AsyncCultivationLogs />} />
            <Route path={`${sitemap[Scenes.DEVICES].path}/*`} element={<AsyncDevices />} />
            <Route path={`${sitemap[Scenes.MY_CULTIVATION].path}/*`} element={<AsyncMyCultivation />} />
            <Route path={`${sitemap[Scenes.ANALYTICS].path}/*`} element={<AsyncAnalytics />} />
            <Route path={`${sitemap[Scenes.SUPPORT].path}/*`} element={<AsyncSupport />} />

            {/* merge next ones inside of bacoffice */}
            {isGlobalOwner && <Route path={`${sitemap[Scenes.BACKOFFICE].path}/*`} element={<AsyncBackoffice />} />}
            {isGlobalOwner && <Route path={`${sitemap[Scenes.STOCK].path}/*`} element={<AsyncStock />} />}
            {isGlobalOwner && <Route path={`${sitemap[Scenes.UTILITIES].path}/*`} element={<UtilitiesScene />} />}

            {idd ? (
              <Route path="*" element={<Navigate to={sitemap[Scenes.DEVICES].routes.home.path} replace={true} state={{ idd }} />} />
            ) : (
              <Route path="*" element={<Navigate to={sitemap[Scenes.MY_CULTIVATION].routes.home.path} replace={true} />} />
            )}
          </Routes>
        </JobExecutionProvider>
      </RealTimeListenerProvider>
    </MQTTClientProvider>
  );
};

const Setup: React.FC = () => {
  const permissions = useSessionPermissions();

  const isGlobalOwner = permissions.hasRole(Role.OWNER);

  const location = useLocation();
  const idd = isIddLocationState(location.state) ? location.state.idd : null;

  return (
    <Routes>
      <Route path={`${sitemap[Scenes.PROFILE].path}/*`} element={<AsyncProfile />} />
      <Route path={`${sitemap[Scenes.SETUP].path}/*`} element={<AsyncSetup />} />
      <Route path={`${sitemap[Scenes.SUPPORT].path}/*`} element={<AsyncSupport />} />

      {/* merge next ones inside of backoffice */}
      {isGlobalOwner && <Route path={`${sitemap[Scenes.BACKOFFICE].path}/*`} element={<AsyncBackoffice />} />}
      {isGlobalOwner && <Route path={`${sitemap[Scenes.STOCK].path}/*`} element={<AsyncStock />} />}
      {isGlobalOwner && <Route path={`${sitemap[Scenes.UTILITIES].path}/*`} element={<UtilitiesScene />} />}

      <Route path="*" element={<Navigate to={sitemap[Scenes.SETUP].routes.organization.path} replace={true} state={{ idd }} />} />
    </Routes>
  );
};

const Invites: React.FC = () => {
  return (
    <Routes>
      <Route path={`${sitemap[Scenes.PROFILE].path}/*`} element={<AsyncProfile />} />
      <Route path={`${sitemap[Scenes.INVITE].path}/*`} element={<AsyncInvite />} />
      <Route path="*" element={<Navigate to={sitemap[Scenes.INVITE].routes.confirmation.path} replace={true} />} />
    </Routes>
  );
};
