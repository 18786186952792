import React from 'react';
import { useRouteError } from 'react-router-dom';
import { AbsoluteCenter } from '@chakra-ui/react';
import { Loading } from '../Loading';
import { ErrorPage } from './ErrorPage';
import { knownBuildIssues } from './knownBuildIssues';

export const RouterErrorHandler: React.FC = () => {
  const error = useRouteError();

  const [showLoading, setShowLoading] = React.useState(true);

  // this is to avoid the error for blinking while is reloading
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (showLoading) {
    return (
      <AbsoluteCenter>
        <Loading />
      </AbsoluteCenter>
    );
  }

  if (error !== null && typeof error === 'object' && 'message' in error) {
    const message = String(error.message);
    const shouldIgnore = knownBuildIssues.some((knowIssue) => message.includes(knowIssue));

    if (shouldIgnore) {
      window.location.reload();
    }
  }

  return <ErrorPage />;
};
