import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { APIError } from '..';
import { useChangeDefaultOrganization } from '../../session/useUpdateDefaultOrganization';
import { useSessionUser } from '../../session';
import { OrganizationSelector } from '../Form/Selectors/OrganizationSelector';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { API } from '../../api/trpc';
import createDebugger from 'debug';

const debug = createDebugger('Growcast:DefaultOrganizationSelector');
export const DefaultOrganizationSelector: React.FC<BoxProps> = (boxProps) => {
  const user = useSessionUser();

  const { onSubmit, error, isLoading } = useChangeDefaultOrganization();

  const queryClient = useQueryClient();

  const onChange = (newValue: string | number) => {
    const organization = Number(newValue);
    if (isNaN(organization)) {
      return null;
    }

    onSubmit(organization);

    const fetchZonesListQueryKey = getQueryKey(API.zones.list, undefined, 'query');

    // invalidate zones list
    void queryClient.invalidateQueries(fetchZonesListQueryKey).catch((error) => debug(error));
  };

  return (
    <Box {...boxProps}>
      <OrganizationSelector selectorProps={{ onChange, value: String(user.defaultOrganization), isLoading }} />

      {error && <APIError error={error} />}
    </Box>
  );
};
