import { getQueryKey } from '@trpc/react-query';
import { AuthenticatedSession, useSession } from '.';
import { API, APIOutputs } from '../api/trpc';
import createDebugger from 'debug';
import { useQueryClient } from '@tanstack/react-query';

const debug = createDebugger('Growcast:useChangeDefaultOrganization');

export type ChangeDefaultOrganizationOutput = APIOutputs['users']['changeDefaultOrganization'];

// this is the key of the query that fetches all the users from the current organization, so we need to refetch this
export const paginatedUserSeachQueryKey = getQueryKey(API.users.infiniteListStaff, undefined, 'infinite');
interface Args {
  onSuccess?: (user: ChangeDefaultOrganizationOutput) => void;
}

export const useChangeDefaultOrganization = (args?: Args) => {
  const { refresh } = useSession<AuthenticatedSession>();

  const queryClient = useQueryClient();

  const onSuccess = (data: ChangeDefaultOrganizationOutput) => {
    queryClient.removeQueries(paginatedUserSeachQueryKey);

    void refresh()
      .then(() => {
        if (args?.onSuccess) {
          args.onSuccess(data);
        }
      })
      .catch((error) => debug(error));
  };

  const { mutate, isLoading, error } = API.users.changeDefaultOrganization.useMutation({
    onSuccess,
  });

  const onSubmit = (organizationId: number) => {
    mutate({ organizationId });
  };

  return {
    isLoading,
    error,
    onSubmit,
  };
};
