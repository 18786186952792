import { AutomationTemplateType, ComparisonOperator, DaysOfWeek, LogicalOperator, NotificationChannel, OutputModeType, Role } from 'common';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { ColorMode } from '../theme';

export const commonFormValidationMessages = defineMessages({
  alarmBiggerThanLimitConflict: {
    id: 'forms.alarm_conflict_bigger_than_limit',
    defaultMessage: 'Este campo debe ser menor al límite superior de las demas alarmas',
  },
  alarmSmallerThanLimitConflict: {
    id: 'forms.alarm_conflict_smaller_than_limit',
    defaultMessage: 'Este campo debe ser mayor al límite inferior de las demas alarmas',
  },
  emptyArray: {
    id: 'forms.field_empty_array',
    defaultMessage: 'Debe seleccionar al menos un elemento',
  },
  required: {
    id: 'forms.field_required',
    defaultMessage: 'Este campo es obligatorio',
  },
  invalidEmail: {
    id: 'forms.invalid_email',
    defaultMessage: 'Introduce un correo electrónico válido',
  },
  invalidPhone: {
    id: 'forms.invalid_phone_number',
    defaultMessage: 'Introduce un número de teléfono válido',
  },
  invalidUsername: {
    id: 'forms.invalid_username',
    defaultMessage: 'Solo se permiten letras Unicode, marcas de caracteres, símbolos, dígitos numéricos y puntuaciones',
  },
  invalidTimeRange: {
    id: 'forms.invalid_time_range',
    defaultMessage: 'El rango entre "inicio" y "fin" no puede superar las 24 hs de duración',
  },
  minTimeDiff: {
    id: 'forms.min_time_diff',
    defaultMessage: 'El rango mínimo permitido es de 1 minuto',
  },
  minLength: {
    id: 'forms.min_length',
    defaultMessage: 'Este campo debe tener al menos {minLength} caracteres',
  },
  maxLength: {
    id: 'forms.max_length',
    defaultMessage: 'Este campo no puede tener más de {maxLength} caracteres',
  },
  numeric: {
    id: 'forms.only_numeric',
    defaultMessage: 'Solo se permiten números en este campo',
  },
  biggerThan: {
    id: 'forms.bigget_than',
    defaultMessage: 'Este campo debe ser mayor a {biggerThan}',
  },
  lowerThan: {
    id: 'forms.lower_than',
    defaultMessage: 'Este campo debe ser menor a {lowerThan}',
  },
  passwordMismatch: {
    id: 'forms.password_mismatch',
    defaultMessage: 'Las contraseñas no coinciden',
  },
  invalidDate: {
    id: 'forms.invalid_date',
    defaultMessage: 'Introduce una fecha válida',
  },
  invalidFormat: {
    id: 'forms.invalid_format',
    defaultMessage: 'Formato inválido',
  },
});

export const commonActionMessages = defineMessages({
  add: {
    id: 'CommonAction.add',
    defaultMessage: 'Agregar',
  },
  acknowledge: {
    id: 'CommonAction.acknowledge',
    defaultMessage: 'Reconocer',
  },
  apply: {
    id: 'CommonAction.apply',
    defaultMessage: 'Aplicar',
  },
  back: {
    id: 'CommonAction.back',
    defaultMessage: 'Regresar',
  },
  cancel: {
    id: 'UnauthCommonAction.cancel',
    defaultMessage: 'Cancelar',
  },
  chooseOne: {
    id: 'CommonAction.choose_one',
    defaultMessage: 'Elija uno/a',
  },
  close: {
    id: 'UnauthCommonAction.close',
    defaultMessage: 'Cerrar',
  },
  configure: {
    id: 'UnauthCommonAction.configure',
    defaultMessage: 'Configurar',
  },
  confirm: {
    id: 'UnauthCommonAction.confirm',
    defaultMessage: 'Confirmar',
  },
  copy: {
    id: 'UnauthCommonAction.copy',
    defaultMessage: 'Copiar',
  },
  create: {
    id: 'UnauthCommonAction.create',
    defaultMessage: 'Crear',
  },
  delete: {
    id: 'UnauthCommonAction.delete',
    defaultMessage: 'Borrar',
  },
  disable: {
    id: 'UnauthCommonAction.disable',
    defaultMessage: 'Desactivar',
  },
  disableState: {
    id: 'UI.disable_state',
    defaultMessage: 'Deshabilitar',
  },
  download: {
    id: 'CommonAction.download',
    defaultMessage: 'Descargar',
  },
  enable: {
    id: 'UnauthCommonAction.enable',
    defaultMessage: 'Activar',
  },
  enableState: {
    id: 'UI.enable_state',
    defaultMessage: 'Habilitar',
  },
  edit: {
    id: 'CommonAction.edit',
    defaultMessage: 'Editar',
  },
  filter: {
    id: 'CommonAction.filter',
    defaultMessage: 'Filtrar',
  },
  hide: {
    id: 'UnauthCommonAction.hide',
    defaultMessage: 'Ocultar',
  },
  invite: {
    id: 'UnauthCommonAction.invite',
    defaultMessage: 'Invitar',
  },
  join: {
    id: 'CommonAction.join',
    defaultMessage: 'Unirse',
  },
  login: {
    id: 'UnauthCommonAction.login',
    defaultMessage: 'Iniciar sesión',
  },
  mute: {
    id: 'CommonAction.mute',
    defaultMessage: 'Silenciar',
  },
  next: {
    id: 'CommonAction.next',
    defaultMessage: 'Siguiente',
  },
  register: {
    id: 'UnauthCommonAction.register',
    defaultMessage: 'Registrarse',
  },
  resendCode: {
    id: 'UnauthCommonAction.resend',
    defaultMessage: 'Reenviar',
  },
  reset: {
    id: 'UnauthCommonAction.reset',
    defaultMessage: 'Restablecer',
  },
  resolve: {
    id: 'CommonAction.resolve',
    defaultMessage: 'Resolver',
  },
  retry: {
    id: 'CommonAction.retry',
    defaultMessage: 'Reintentar',
  },
  send: {
    id: 'UnauthCommonAction.send',
    defaultMessage: 'Enviar',
  },
  seePrevious: {
    id: 'CommonAction.see_previous',
    defaultMessage: 'Ver anteriores',
  },
  settings: {
    id: 'CommmonAction.settings',
    defaultMessage: 'Ajustes',
  },
  save: {
    id: 'UnauthCommonAction.save',
    defaultMessage: 'Guardar',
  },
  skip: {
    id: 'CommonAction.skip',
    defaultMessage: 'Omitir',
  },
  show: {
    id: 'UnauthCommonAction.show',
    defaultMessage: 'Mostrar',
  },
  sync: {
    id: 'CommonAction.sync',
    defaultMessage: 'Sincronizar',
  },
  update: {
    id: 'CommonAction.update',
    defaultMessage: 'Actualizar',
  },
  verify: {
    id: 'CommonAction.verify',
    defaultMessage: 'Verificar',
  },
});

export const commonFormMessages = defineMessages({
  address: {
    id: 'UI.address',
    defaultMessage: 'Dirección',
  },
  advancedOptions: {
    id: 'UI.advanced_options',
    defaultMessage: 'Opciones avanzadas',
  },
  alarm: {
    id: 'UI.alarm',
    defaultMessage: 'Alarma',
  },
  analog: {
    id: 'UI.analog',
    defaultMessage: 'Analógica',
  },
  authenticationCode: {
    id: 'UnauthUI.authentication_code',
    defaultMessage: 'Código de Autenticación',
  },
  availableInApp: {
    id: 'UI.available_in_app',
    defaultMessage: 'Disponible en la aplicación',
  },
  automations: {
    id: 'UI.automation',
    defaultMessage: 'Automatizaciones',
  },
  country: {
    id: 'UI.country',
    defaultMessage: 'País',
  },
  critical: {
    id: 'UI.critical',
    defaultMessage: 'Crítica',
  },
  cultivationRecord: {
    id: 'UI.cultivation_record',
    defaultMessage: 'Nota',
  },
  cultivationRecordType: {
    id: 'UI.cultivation_record_type',
    defaultMessage: 'Tipo de notas',
  },
  date: {
    id: 'UI.date',
    defaultMessage: 'Fecha',
  },
  daysOfWeek: {
    id: 'UI.days',
    defaultMessage: 'Días de la semana',
  },
  description: {
    id: 'UI.description',
    defaultMessage: 'Descripción',
  },
  deviceId: {
    id: 'UI.device_id',
    defaultMessage: 'ID de Dispositivo',
  },
  device: {
    id: 'UI.device',
    defaultMessage: 'Dispositivo',
  },
  duration: {
    id: 'UI.duration',
    defaultMessage: 'Duración',
  },
  digital: {
    id: 'UI.digital',
    defaultMessage: 'Digital',
  },
  email: {
    id: 'UnauthUI.email',
    defaultMessage: 'Correo electrónico',
  },
  end: {
    id: 'UI.end',
    defaultMessage: 'Fin',
  },
  environment: {
    id: 'UI.environment',
    defaultMessage: 'Entorno',
  },
  familyName: {
    id: 'UI.familyName',
    defaultMessage: 'Apellido',
  },
  file: {
    id: 'UI.file',
    defaultMessage: 'Archivo',
  },
  firmware: {
    id: 'UI.firmware',
    defaultMessage: 'Firmware',
  },
  firstname: {
    id: 'UnauthUI.first_name',
    defaultMessage: 'Nombre/s',
  },
  hardware: {
    id: 'UI.hardware',
    defaultMessage: 'Hardware',
  },
  hardwareCapability: {
    id: 'UI.hardware_capability',
    defaultMessage: 'Prestación de hardware',
  },
  hours: {
    id: 'UI.hours',
    defaultMessage: 'Horas',
  },
  identifier: {
    id: 'UI.identifier',
    defaultMessage: 'Identificador',
  },
  idd: {
    id: 'UI.device_idd',
    defaultMessage: 'IDD',
  },
  IOHardwareDescription: {
    id: 'UI.IO_hardware_description',
    defaultMessage: 'Descripción de hardware de IO',
  },
  input: {
    id: 'UI.input',
    defaultMessage: 'Entrada',
  },
  lastname: {
    id: 'UnauthUI.last_name',
    defaultMessage: 'Apellido',
  },
  locale: {
    id: 'UnauthUI.locale',
    defaultMessage: 'Idioma',
  },
  lot: {
    id: 'UI.lot',
    defaultMessage: 'Lote',
  },
  mac: {
    id: 'UI.mac',
    defaultMessage: 'Dirección MAC',
  },
  mainboard: {
    id: 'UI.mainboard',
    defaultMessage: 'Módulo principal',
  },
  minutes: {
    id: 'UI.minute',
    defaultMessage: 'Minutos',
  },
  name: {
    id: 'UI.name',
    defaultMessage: 'Nombre',
  },
  notifications: {
    id: 'UI.notifications',
    defaultMessage: 'Notificaciones',
  },
  notificationChannelDescription: {
    id: 'UI.channels_description',
    defaultMessage: 'Te notificaremos vía:',
  },
  operator: {
    id: 'UI.operator',
    defaultMessage: 'Operador',
  },
  optional: {
    id: 'UI.optional',
    defaultMessage: 'Opcional',
  },
  options: {
    id: 'UI.options',
    defaultMessage: 'Opciones',
  },
  output: {
    id: 'UI.output',
    defaultMessage: 'Salida',
  },
  password: {
    id: 'UnauthUI.password',
    defaultMessage: 'Contraseña',
  },
  permissions: {
    id: 'UI.permissions',
    defaultMessage: 'Control de acceso',
  },
  phone: {
    id: 'UnauthUI.phone',
    defaultMessage: 'Número de teléfono',
  },
  prefix: {
    id: 'UnauthUI.prefix',
    defaultMessage: 'Prefijo',
  },
  quantity: {
    id: 'UI.quantity',
    defaultMessage: 'Cantidad',
  },
  range: {
    id: 'UI.range',
    defaultMessage: 'Rango',
  },
  rangeMax: {
    id: 'UI.rangeMax',
    defaultMessage: 'Rango máximo',
  },
  rangeMin: {
    id: 'UI.rangeMin',
    defaultMessage: 'Rango mínimo',
  },
  reference: {
    id: 'UI.reference',
    defaultMessage: 'Referencia',
  },
  repeat: {
    id: 'UI.repeat',
    defaultMessage: 'Repetir',
  },
  repeatPassword: {
    id: 'UnauthUI.repeat_password',
    defaultMessage: 'Repetir contraseña',
  },
  required: {
    id: 'UI.required',
    defaultMessage: 'Requerida',
  },
  requiredOrganizationPermissions: {
    id: 'UI.required_organization_permissions',
    defaultMessage: 'Debe asignar al menos un rol en la organización o sala',
  },
  role: {
    id: 'UI.role',
    defaultMessage: 'Rol',
  },
  safetyState: {
    id: 'UI.safety_state',
    defaultMessage: 'Estado seguro',
  },
  safetyStateDescription: {
    id: 'UI.safety_state_description',
    defaultMessage: 'Si el dispositivo no puede obtener el valor de la variable a comparar, usará este estado',
  },
  seconds: {
    id: 'UI.seconds',
    defaultMessage: 'Segundos',
  },
  selectRange: {
    id: 'UI.select_range',
    defaultMessage: 'Rango de fechas',
  },
  start: {
    id: 'UI.start',
    defaultMessage: 'Inicio',
  },
  step: {
    id: 'UI.step',
    defaultMessage: 'Incremento mínimo',
  },
  theme: {
    id: 'UnauthUI.theme',
    defaultMessage: 'Apariencia',
  },
  time: {
    id: 'UI.time',
    defaultMessage: 'Hora',
  },
  timezone: {
    id: 'UI.timezone',
    defaultMessage: 'Zona horaria',
  },
  type: {
    id: 'UI.type',
    defaultMessage: 'Tipo',
  },
  initTime: {
    id: 'UI.initTime',
    defaultMessage: 'Hora de inicio',
  },
  unit: {
    id: 'UI.unit',
    defaultMessage: 'Unidad',
  },
  username: {
    id: 'UnauthUI.username',
    defaultMessage: 'Usuario',
  },
  version: {
    id: 'UI.version',
    defaultMessage: 'Versión',
  },
  way: {
    id: 'UI.way',
    defaultMessage: 'Sentido',
  },
  whatsapp: {
    id: 'UI.whatsapp',
    defaultMessage: 'Whatsapp',
  },
  zone: {
    id: 'UI.zone',
    defaultMessage: 'Sala',
  },
  zoneType: {
    id: 'UI.zone_type_label',
    defaultMessage: 'Tipo de sala',
  },
});

export const commonScenesMessages = defineMessages({
  administration: {
    id: 'scene.administration',
    defaultMessage: 'Administración',
  },
  analytics: {
    id: 'scene.analytics',
    defaultMessage: 'Analítica',
  },
  automations: {
    id: 'scene.automations',
    defaultMessage: 'Automatizaciones',
  },
  chat: {
    id: 'scene.chat',
    defaultMessage: 'Chat',
  },
  dashboard: {
    id: 'scene.dashboard',
    defaultMessage: 'Panel',
  },
  devices: {
    id: 'scene.devices',
    defaultMessage: 'Mis Dispositivos',
  },
  backoffice: {
    id: 'scene.backoffice',
    defaultMessage: 'Backoffice',
  },
  event: {
    id: 'scene.events',
    defaultMessage: 'Evento',
  },
  cultivationLogs: {
    id: 'scene.cultivation_logs',
    defaultMessage: 'Bitácora',
  },
  cultivationRecordType: {
    id: 'scene.cultivation_record_type_label',
    defaultMessage: 'Tipo de notas',
  },
  firmware: {
    id: 'scene.firmware_label',
    defaultMessage: 'Firmware',
  },
  issue_n: {
    id: 'scene.issue_id',
    defaultMessage: 'Caso Nº {id}',
  },
  issues: {
    id: 'scene.issues',
    defaultMessage: 'Casos',
  },
  logs: {
    id: 'scene.logs',
    defaultMessage: 'Bitácora',
  },
  module: {
    id: 'scene.module',
    defaultMessage: 'Módulo',
  },
  hardware: {
    id: 'scene.hardware_label',
    defaultMessage: 'Módulos',
  },
  identifiers: {
    id: 'scene.identifiers',
    defaultMessage: 'Identificadores',
  },
  IOs: {
    id: 'scene.IOs_label',
    defaultMessage: 'IOs',
  },
  invite: {
    id: 'scene.invite_confirmation',
    defaultMessage: 'Confirmación',
  },
  myCultivation: {
    id: 'UI.my_cultivation',
    defaultMessage: 'Salas',
  },
  organization: {
    id: 'scene.organization',
    defaultMessage: 'Mi organización',
  },
  staff: {
    id: 'scene.staff',
    defaultMessage: 'Personal',
  },
  stock: {
    id: 'scene.stock',
    defaultMessage: 'Stock',
  },
  support: {
    id: 'scene.support',
    defaultMessage: 'Soporte',
  },
  tools: {
    id: 'scene.tools',
    defaultMessage: 'Herramientas',
  },
  zoneType: {
    id: 'scene.zone_type',
    defaultMessage: 'Tipo de sala',
  },
});

export const commonDataViewMessages = defineMessages({
  all: {
    id: 'UI.all',
    defaultMessage: 'Todos',
  },
  automatic: {
    id: 'UI.automatic',
    defaultMessage: 'Auto',
  },
  automation: {
    id: 'UI.automation',
    defaultMessage: 'Automatización',
  },
  charCount: {
    id: 'UI.char_count',
    defaultMessage: '{count} / {total}',
  },
  connected: {
    id: 'UI.connected',
    defaultMessage: 'Conectado',
  },
  created: {
    id: 'UI.created',
    defaultMessage: 'Creación',
  },
  customAutomationType: {
    id: 'UI.custom_automation_type',
    defaultMessage: 'Personalizada',
  },
  device: {
    id: 'UI.device',
    defaultMessage: 'Dispositivo',
  },
  disabled: {
    id: 'UI.disabled',
    defaultMessage: 'Deshabilitado',
  },
  disconnectedDevice: {
    id: 'UI.disconnected_device',
    defaultMessage: 'Este dispositivo está desconectado. ',
  },
  disconnected: {
    id: 'UI.disconnected',
    defaultMessage: 'Desconectado',
  },
  displayAlarms: {
    id: 'UI.display_alarms',
    defaultMessage: 'Ver alarmas',
  },
  enabled: {
    id: 'UI.enabled',
    defaultMessage: 'Habilitado',
  },
  emptyList: {
    id: 'CustomList.empty_list',
    defaultMessage: 'No hay elementos para mostrar',
  },
  failure: {
    id: 'UI.failure',
    defaultMessage: 'En falla',
  },
  from: {
    id: 'UI.from',
    defaultMessage: 'Desde',
  },
  hoursAbbreviation: {
    id: 'UI.hour_abbreviation',
    defaultMessage: '{hours}h',
  },
  help: {
    id: 'UI.help',
    defaultMessage: 'Ayuda',
  },
  IOs: {
    id: 'UI.IOs',
    defaultMessage: 'Controladores y Sensores',
  },
  info: {
    id: 'UI.info',
    defaultMessage: 'Información',
  },
  inputs: {
    id: 'UI.inputs',
    defaultMessage: 'Sensores',
  },
  inUse: {
    id: 'UI.in_use',
    defaultMessage: 'Activa',
  },
  lastUpdate: {
    id: 'UI.lastUpdate',
    defaultMessage: 'Última actualización',
  },

  last24Hours: {
    id: 'UI.last_24_hours',
    defaultMessage: 'Últimas 24 horas',
  },
  loading: {
    id: 'UI.loading',
    defaultMessage: 'Cargando...',
  },
  me: {
    id: 'UI.me',
    defaultMessage: 'Yo',
  },
  minutesAbbreviation: {
    id: 'UI.minute_abbreviation',
    defaultMessage: '{minutes}m',
  },
  module: {
    id: 'UI.module',
    defaultMessage: 'Módulo',
  },
  modules: {
    id: 'UI.modules',
    defaultMessage: 'Módulos',
  },
  network: {
    id: 'UI.network',
    defaultMessage: 'Red',
  },
  noAvailableInApp: {
    id: 'UI.no_available_in_app',
    defaultMessage: 'No disponible en aplicación',
  },
  noDataAvailable: {
    id: 'UI.no_data_available',
    defaultMessage: 'No hay información disponible',
  },
  off: {
    id: 'UI.off',
    defaultMessage: 'Apagado',
  },
  on: {
    id: 'UI.on',
    defaultMessage: 'Encendido',
  },
  online: {
    id: 'UI.online',
    defaultMessage: 'En línea',
  },
  outputs: {
    id: 'UI.outputs',
    defaultMessage: 'Controladores',
  },
  pending: {
    id: 'UI.pending_invites',
    defaultMessage: 'Pendientes',
  },
  query: {
    id: 'UI.query',
    defaultMessage: 'Consulta',
  },
  releaseDate: {
    id: 'UI.release_date',
    defaultMessage: 'Fecha de publicación',
  },
  resolved: {
    id: 'UI.resolved',
    defaultMessage: 'Resuelto',
  },
  secondsAbbreviation: {
    id: 'UI.second_abbreviation',
    defaultMessage: '{seconds}s',
  },
  securityToken: {
    id: 'UI.security_token',
    defaultMessage: 'Token de seguridad',
  },
  signalLevel: {
    id: 'UI.signal_level',
    defaultMessage: 'Intensidad de señal',
  },
  source: {
    id: 'UI.data_source',
    defaultMessage: 'Fuente',
  },
  status: {
    id: 'UI.status',
    defaultMessage: 'Estado',
  },
  templates: {
    id: 'UI.templates',
    defaultMessage: 'Plantillas',
  },
  to: {
    id: 'UI.to',
    defaultMessage: 'Hasta',
  },
  today: {
    id: 'UI.today',
    defaultMessage: 'Hoy',
  },
  turnOn: {
    id: 'UI.turn_on',
    defaultMessage: 'Encendió',
  },
  turnOff: {
    id: 'UI.turn_off',
    defaultMessage: 'Apagó',
  },
  v: {
    id: 'UI.versionNumber',
    defaultMessage: 'v{version}',
  },
  value: {
    id: 'UI.value',
    defaultMessage: 'Valor',
  },
  variable: {
    id: 'UI.variable',
    defaultMessage: 'Variable',
  },
  variables: {
    id: 'UI.variables',
    defaultMessage: 'Variables',
  },
  visibility: {
    id: 'UI.visibility',
    defaultMessage: 'Visibilidad',
  },
});

export const RoleMessages = defineMessages<Role, MessageDescriptor>({
  [Role.GROWER]: {
    id: 'Role.grower',
    defaultMessage: 'Cultivador',
  },
  [Role.OPERATOR]: {
    id: 'Role.operator',
    defaultMessage: 'Operario',
  },
  [Role.OWNER]: {
    id: 'Role.owner',
    defaultMessage: 'Administrador',
  },
  [Role.TRANSLATOR]: {
    id: 'Role.translator',
    defaultMessage: 'Traductor',
  },
});

export const controlTypeMessages = defineMessages<'auto' | 'manual', MessageDescriptor>({
  auto: {
    id: 'ControlType.auto',
    defaultMessage: 'Auto',
  },
  manual: {
    id: 'ControlType.manual',
    defaultMessage: 'Manual',
  },
});

// automations
export const commonDaysMessages = defineMessages<DaysOfWeek, MessageDescriptor>({
  [DaysOfWeek.SUNDAY]: {
    id: 'DaysOfWeek.sunday',
    defaultMessage: 'Domingo',
  },
  [DaysOfWeek.MONDAY]: {
    id: 'DaysOfWeek.momday',
    defaultMessage: 'Lunes',
  },
  [DaysOfWeek.TUESDAY]: {
    id: 'DaysOfWeek.tuesday',
    defaultMessage: 'Martes',
  },
  [DaysOfWeek.WEDNESDAY]: {
    id: 'DaysOfWeek.wednesdat',
    defaultMessage: 'Miércoles',
  },
  [DaysOfWeek.THURSDAY]: {
    id: 'DaysOfWeek.thursday',
    defaultMessage: 'Jueves',
  },
  [DaysOfWeek.FRIDAY]: {
    id: 'DaysOfWeek.friday',
    defaultMessage: 'Viernes',
  },
  [DaysOfWeek.SATURDAY]: {
    id: 'DaysOfWeek.saturday',
    defaultMessage: 'Sabado',
  },
});

export const commonAutomationTemplateTypeMessages = defineMessages<AutomationTemplateType, MessageDescriptor>({
  [AutomationTemplateType.ADVANCED]: {
    id: 'AutomationTemplateType.advanced',
    defaultMessage: 'Avanzado',
  },
  [AutomationTemplateType.CYCLE_SCHEDULE]: {
    id: 'AutomationTemplateType.cycle_schedule',
    defaultMessage: 'Temporizador cíclico',
  },
  [AutomationTemplateType.MULTIPLE_SCHEDULE]: {
    id: 'AutomationTemplateType.multiple_schedule',
    defaultMessage: 'Intervalos múltiples',
  },
  [AutomationTemplateType.SCHEDULE]: {
    id: 'AutomationTemplateType.schedule',
    defaultMessage: 'Intervalo programado',
  },
  [AutomationTemplateType.SIMPLE_COMPARATOR]: {
    id: 'AutomationTemplateType.simple_comparator',
    defaultMessage: 'Por variable',
  },
});

export const commonAutomationTemplateTypeDescriptions = defineMessages<AutomationTemplateType, MessageDescriptor>({
  [AutomationTemplateType.ADVANCED]: {
    id: 'AutomationTemplateType.advanced_description',
    defaultMessage: 'Facilita la creacion de automatizaciones más complejas y personalizadas',
  },
  [AutomationTemplateType.CYCLE_SCHEDULE]: {
    id: 'AutomationTemplateType.cycle_schedule_description',
    defaultMessage: 'Configura la activación por ciclos de encendido y apagado',
  },
  [AutomationTemplateType.MULTIPLE_SCHEDULE]: {
    id: 'AutomationTemplateType.multiple_schedule_description',
    defaultMessage: 'Establece varios horarios con diferentes rangos de duración a lo largo del día',
  },
  [AutomationTemplateType.SCHEDULE]: {
    id: 'AutomationTemplateType.schedule_description',
    defaultMessage: 'Permite el encendido de dispositivos durante un rango horario específico',
  },
  [AutomationTemplateType.SIMPLE_COMPARATOR]: {
    id: 'AutomationTemplateType.simple_comparator_description',
    defaultMessage: 'Activa dispositivos utilizando el estado de otra variable como referencia',
  },
});

export const timeComparisonOperatorMessages = defineMessages<ComparisonOperator, MessageDescriptor>({
  [ComparisonOperator.GreaterThan]: {
    id: 'TimeComparisonOperator.greater_than',
    defaultMessage: 'Despues de',
  },
  [ComparisonOperator.LessThan]: {
    id: 'TimeComparisonOperator.less_than',
    defaultMessage: 'Antes de',
  },
});

export const numericComparisonOperatorMessages = defineMessages<ComparisonOperator, MessageDescriptor>({
  [ComparisonOperator.GreaterThan]: {
    id: 'NumericComparisonOperator.greater_than',
    defaultMessage: 'Mayor a',
  },
  [ComparisonOperator.LessThan]: {
    id: 'NumericComparisonOperator.less_than',
    defaultMessage: 'Menor a',
  },
});

export const logicalOperatorMessages = defineMessages<LogicalOperator, MessageDescriptor>({
  [LogicalOperator.AND]: {
    id: 'LogicalOperator.and',
    defaultMessage: 'AND: Todas activas',
  },
  [LogicalOperator.OR]: {
    id: 'LogicalOperator.or',
    defaultMessage: 'OR: Al menos unas activa',
  },
});

export const automationOutputModeMessages = defineMessages<OutputModeType, MessageDescriptor>({
  [OutputModeType.CONSTANT]: {
    id: 'OutputModeType.constant',
    defaultMessage: 'Constante',
  },
  [OutputModeType.PULSE]: {
    id: 'OutputModeType.pulse',
    defaultMessage: 'De a pulsos',
  },
});

// notifications
export const notificationChannelMessages = defineMessages<NotificationChannel, MessageDescriptor>({
  [NotificationChannel.EMAIL]: {
    id: 'NotificationChannel.email',
    defaultMessage: 'E-mail',
  },
  [NotificationChannel.TELEGRAM]: {
    id: 'NotificationChannel.telegram',
    defaultMessage: 'Telegram',
  },
  [NotificationChannel.WHATSAPP]: {
    id: 'NotificationChannel.whatsapp',
    defaultMessage: 'Whatsapp',
  },
});

// theme
export const colorModeMessages = defineMessages<ColorMode, MessageDescriptor>({
  [ColorMode.DARK]: {
    id: 'Theme.label_dark_theme',
    defaultMessage: 'Oscuro',
  },
  [ColorMode.LIGHT]: {
    id: 'Theme.label_light_theme',
    defaultMessage: 'Claro',
  },
  [ColorMode.SYSTEM]: {
    id: 'Theme.label_system_theme',
    defaultMessage: 'Sistema',
  },
});
