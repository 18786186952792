import React from 'react';
import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  StackProps,
} from '@chakra-ui/react';
import { AuthenticatedSession, useSession } from '../../session';
import { Sidebar } from './Sidebar';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { IconType } from 'react-icons';
import {
  FaArrowRightFromBracket as LogoutIcon,
  FaIdCard as ProfileIcon,
  FaShield as SecurityIcon,
  FaGear as PreferencesIcon,
  FaCircleExclamation as NotificationsIcon,
} from 'react-icons/fa6';
import { TranslatedMessage } from '../../i18n';
import { useSignOut } from '../../session/useSignOut';
import { useNavigate } from 'react-router-dom';
import { sitemap } from '../../routing';

const messages = defineMessages({
  account: {
    id: 'Sidebar.account_label',
    defaultMessage: 'Cuenta',
  },
  profile: {
    id: 'Sidebar.profile_entry',
    defaultMessage: 'Perfil',
  },
  preferences: {
    id: 'Sidebar.preferences_entry',
    defaultMessage: 'Preferencias',
  },
  notifications: {
    id: 'Sidebar.notificactions_entry',
    defaultMessage: 'Notificaciones',
  },
  security: {
    id: 'Sidebar.security_entry',
    defaultMessage: 'Seguridad',
  },
  logOut: {
    id: 'Account.sign_out_label',
    defaultMessage: 'Cerrar sesión',
  },
});

export const Header: React.FC<StackProps> = (props) => {
  const stackProps = {
    ...props,
    justifyContent: 'space-between',
    alignItems: 'start',
    py: 2,
    px: 4,
    zIndex: 'overlay',
  };

  return (
    <HStack {...stackProps} bgGradient={'linear(appBg 75%, appBgAlpha 100%)'} backdropFilter="blur(10px) grayscale(.8)">
      <Sidebar />
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <AccountMenu />
      </HStack>
    </HStack>
  );
};

export const AccountMenu: React.FC<BoxProps> = (props) => {
  const { signOut } = useSignOut();
  const { user } = useSession<AuthenticatedSession>();
  const userFullName = user.getUsername();

  const navigate = useNavigate();
  const getInitials = (name: string): string => {
    const words = name.split(' ');

    const initials = words.reduce((initials, word) => {
      if (!word) {
        return initials;
      }

      return initials.concat(word[0]);
    }, '');

    return initials.toUpperCase();
  };

  return (
    <Box {...props}>
      <Menu>
        <MenuButton>
          <Avatar name={userFullName} getInitials={getInitials} />
        </MenuButton>
        <MenuList>
          <ThemedMenuItem
            icon={ProfileIcon as IconType}
            label={messages.profile}
            onClick={() => navigate(sitemap.profile.routes.personalInfo.path)}
          />
          <ThemedMenuItem
            icon={PreferencesIcon as IconType}
            label={messages.preferences}
            onClick={() => navigate(sitemap.profile.routes.preferences.path)}
          />
          <ThemedMenuItem
            icon={NotificationsIcon as IconType}
            label={messages.notifications}
            onClick={() => navigate(sitemap.profile.routes.notifications.path)}
          />
          <ThemedMenuItem
            icon={SecurityIcon as IconType}
            label={messages.security}
            onClick={() => navigate(sitemap.profile.routes.security.path)}
          />
          <Divider my={2} />
          <ThemedMenuItem icon={LogoutIcon as IconType} label={messages.logOut} onClick={signOut} color={'error'} />
        </MenuList>
      </Menu>
    </Box>
  );
};

interface ThemedMenuItemProps extends Omit<MenuItemProps, 'icon'> {
  icon: IconType;
  label: MessageDescriptor;
  onClick: () => void;
}
export const ThemedMenuItem: React.FC<ThemedMenuItemProps> = (props) => {
  const { label, icon, onClick, ...menuItemProps } = props;

  return (
    <MenuItem onClick={onClick} {...menuItemProps}>
      <HStack spacing={5}>
        <Icon as={icon} />
        <TranslatedMessage message={label} fontSize={'lg'} />
      </HStack>
    </MenuItem>
  );
};
