import { AbsoluteCenter, Button, Heading, Text } from '@chakra-ui/react';

/**
 * There is no translation here because this can be shown outside the translation provider.
 * TODO: implement intl by using static messages and browser lang
 */

export const ErrorPage: React.FC = () => {
  const reload = () => {
    window.location.reload();
  };

  return (
    <AbsoluteCenter>
      <Heading size={'md'}>Error inesperado</Heading>
      <Text>
        No pudimos cargar una parte de la plataforma. Esto puede deberse a un problema de red o a un error interno. Por favor, intente
        recargar la página para solucionarlo. Si el problema persiste, contáctenos.
      </Text>
      <Button onClick={reload} size={'md'}>
        Recargar
      </Button>
    </AbsoluteCenter>
  );
};
