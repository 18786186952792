/**
 * This is temporary, i'm trying to fix dynamic import
 * issues that are thrown when a user tries to fetch a chunk
 * that is no longer available due a deploy in middle of the session.
 */
export const knownBuildIssues = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed.',
  'undefined is not an object',
  "'text/html' is not a valid JavaScript MIME type.",
  'Load failed',
  'JSON Parse error: Unexpected EOF',
  'Method not found',
];
