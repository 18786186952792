import { Box, BoxProps } from '@chakra-ui/react';
import { APIError } from '../..';
import { Selector } from './ReactSelect/Selector';
import { ReactSelectOption, SelectorOnChange, SelectorProps, isMultiSelector } from './ReactSelect';
import { API } from '../../../api/trpc';

interface Props extends BoxProps {
  selectorProps: SelectorProps;
}

export const OrganizationSelector: React.FC<Props> = (props) => {
  const { selectorProps, ...boxProps } = props;

  const { data, isLoading, error } = API.organizations.list.useQuery();

  const formatOption = (id: string | number): ReactSelectOption => {
    const organization = data?.find((organization) => organization.id === Number(id));

    if (!organization) {
      return null;
    }

    return {
      value: id,
      label: organization.name,
    };
  };

  const options = data?.map((organization) => formatOption(organization.id));

  const onChange = (value: SelectorOnChange) => {
    if (isMultiSelector(selectorProps)) {
      const newValue = value as Array<ReactSelectOption> | null;
      const selectedValues = (newValue ?? []).map((option) => option?.value ?? '');

      selectorProps.onChange(selectedValues);
    } else {
      const newValue = value as ReactSelectOption | null;
      const selectedValue = newValue?.value ?? '';

      selectorProps.onChange(selectedValue);
    }
  };

  const defaultValue = selectorProps?.defaultValue
    ? isMultiSelector(selectorProps)
      ? selectorProps.defaultValue.map(formatOption)
      : formatOption(selectorProps.defaultValue)
    : undefined;

  const value = selectorProps?.value
    ? isMultiSelector(selectorProps)
      ? selectorProps.value.map(formatOption)
      : formatOption(selectorProps.value)
    : undefined;

  return (
    <Box {...boxProps}>
      <Selector
        {...selectorProps}
        isLoading={isLoading || selectorProps.isLoading}
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={null}
      />
      {error && <APIError error={error} />}
    </Box>
  );
};
