import { Stack, StackProps, Button, Heading, Icon } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TranslatedMessage } from '../i18n';
import { commonActionMessages } from '../i18n/commonMessages';
import { FaArrowLeftLong as BackIcon } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { errorMessages } from '../components/Error/formatter';

export const NotFound: React.FC<StackProps> = (props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <Stack alignItems={'center'} {...props}>
      <Heading colorScheme="gray" size={'lg'}>
        404
      </Heading>
      <TranslatedMessage message={errorMessages.notFound} colorScheme="gray" />
      <Button onClick={onClick} variant={'link'} alignItems={'center'} colorScheme="brand" gap={2}>
        <Icon as={BackIcon as IconType} />
        <TranslatedMessage message={commonActionMessages.back} />
      </Button>
    </Stack>
  );
};
