import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { sitemap } from '../../routing';
import { ScaleAnimation } from '../../components';

const Tools = React.lazy(() => import('./Tools'));

const Index: React.FC = () => {
  return (
    <Routes>
      <Route
        path={sitemap.utilities.routes.tools.route}
        element={
          <ScaleAnimation>
            <Tools />
          </ScaleAnimation>
        }
      />

      <Route path="*" element={<Navigate to={sitemap.utilities.routes.tools.route} replace={true} />} />
    </Routes>
  );
};

export default Index;
