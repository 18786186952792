export enum Scenes {
  ANALYTICS = 'analytics',
  ORGANIZATION = 'organization',
  AUTOMATIONS = 'automations',
  BACKOFFICE = 'backoffice',
  CULTIVATION_LOGS = 'cultivationLogs',
  DEVICES = 'devices',
  INVITE = 'invite',
  MY_CULTIVATION = 'myCultivation',
  PROFILE = 'profile',
  SETUP = 'setup',
  STOCK = 'stock',
  SUPPORT = 'support',
  UNAUTH = 'unauth',
  UTILITIES = 'utilities',
}

interface Route {
  name: string;
  path: string;
  route: string;
  buildPath?: (...args: Array<string>) => string;
  // roles?: Array<Roles>;
}

interface Scene {
  name: string;
  path: string;
  routes: Record<string, Route>;
  // roles?: Array<Roles>;
}

export type Sitemap = Record<Scenes, Scene>;

export function createSitemap<S extends Record<Scenes, Scene>>(sitemap: S): S {
  return sitemap;
}

export const sitemap = createSitemap({
  [Scenes.ANALYTICS]: {
    name: 'Analitics',
    path: '/analitics',
    routes: {
      dynamics: {
        name: 'Dynamics',
        path: '/analitics/dynamics',
        route: 'dynamics',
      },
    },
  },
  [Scenes.ORGANIZATION]: {
    name: 'Organization',
    path: '/organization',
    routes: {
      home: {
        name: 'Home',
        path: '/organization/home',
        route: 'home',
      },
      user: {
        name: 'User profile',
        path: '/organization/:username',
        buildPath: (username: string) => `/organization/${username}`,
        route: ':username',
      },
    },
  },
  [Scenes.AUTOMATIONS]: {
    name: 'Automations',
    path: '/automations',
    routes: {
      creator: {
        name: 'automations',
        path: '/automations/new',
        route: 'new',
      },
      view: {
        name: 'Automation View',
        path: '/automations/:id',
        buildPath: (id: string) => `/automations/${id}`,
        route: '/:id',
      },
      list: {
        name: 'Automation List',
        path: '/automations/list',
        route: 'list',
      },
      advanced: {
        name: 'Advanced Automation',
        path: '/automations/advanced',
        route: 'advanced',
      },
    },
  },
  [Scenes.BACKOFFICE]: {
    name: 'Back office',
    path: '/backoffice',
    routes: {
      firmwareList: {
        name: 'Firmware List',
        path: '/backoffice/firmware',
        route: 'firmware',
      },
      firmwareEditor: {
        name: 'Firmware Editor',
        buildPath: (id: string) => `/backoffice/firmware/${id}`,
        path: '/backoffice/firmware/:id',
        route: '/firmware/:id',
      },
      hardwareList: {
        name: 'Hardware List',
        path: '/backoffice/hardware',
        route: '/hardware',
      },
      hardwareEditor: {
        name: 'Hardware Editor',
        buildPath: (id: string) => `/backoffice/hardware/${id}`,
        path: '/backoffice/hardware/:id',
        route: '/hardware/:id',
      },
      identifierList: {
        name: 'Identifiers List',
        path: '/backoffice/identifier',
        route: '/identifier',
      },
      identifierEditor: {
        name: 'Identifier Editor',
        buildPath: (id: string) => `/backoffice/identifier/${id}`,
        path: '/backoffice/identifier/:id',
        route: '/identifier/:id',
      },
      IOList: {
        name: 'IO List',
        path: '/backoffice/io',
        route: '/io',
      },
      IOEditor: {
        name: 'IO Editor',
        buildPath: (id: string) => `/backoffice/io/${id}`,
        path: '/backoffice/io/:id',
        route: '/io/:id',
      },
      cultivationRecordTypeList: {
        name: 'Cultivation Record Type List',
        path: '/backoffice/cultivation-record-type',
        route: '/cultivation-record-type',
      },
      cultivationRecordTypeEditor: {
        name: 'Cultivation Record Type Editor',
        buildPath: (id: string) => `/backoffice/cultivation-record-type/${id}`,
        path: '/backoffice/cultivation-record-type/:id',
        route: '/cultivation-record-type/:id',
      },
      zoneTypeList: {
        name: 'Zone Type List',
        path: '/backoffice/zone-type',
        route: '/zone-type',
      },
      zoneTypeEditor: {
        name: 'Zone Type Editor',
        buildPath: (id: string) => `/backoffice/zone-type/${id}`,
        path: '/backoffice/zone-type/:id',
        route: '/zone-type/:id',
      },
      backofficeHome: {
        name: 'backoffice',
        path: '/backoffice/home',
        route: 'home',
      },
    },
  },
  [Scenes.CULTIVATION_LOGS]: {
    name: 'Cultivation logs',
    path: '/cultivation-logs',
    routes: {
      list: {
        name: 'Cultivation Log List',
        path: '/cultivation-logs/list',
        route: 'list',
      },
      editor: {
        name: 'Cultivation Log Viewer',
        buildPath: (id: string) => `/cultivation-logs/${id}`,
        path: '/cultivation-logs/:id',
        route: '/:id',
      },
    },
  },
  [Scenes.DEVICES]: {
    name: 'Devices',
    path: '/devices',
    routes: {
      home: {
        name: 'Home',
        path: '/devices/home',
        route: 'home',
      },
      devices: {
        name: 'Devices',
        path: '/devices/:id',
        buildPath: (id: string) => `/devices/${id}`,
        route: '/:id',
      },
      modules: {
        name: 'Modules',
        path: '/devices/:deviceId/modules/:moduleId',
        buildPath: (deviceId: string, moduleId: string) => `/devices/${deviceId}/modules/${moduleId}`,
        route: '/:deviceId/modules/:moduleId',
      },
      ios: {
        name: 'IOs',
        path: '/devices/:deviceId/modules/:moduleId/ios/:ioId',
        buildPath: (deviceId: string, moduleId: string, ioId: string) => `/devices/${deviceId}/modules/${moduleId}/ios/${ioId}`,
        route: '/:deviceId/modules/:moduleId/ios/:ioId',
      },
    },
  },
  [Scenes.INVITE]: {
    name: 'Invite',
    path: '/invite',
    routes: {
      confirmation: {
        name: 'Invite',
        path: '/invite/confirmation',
        route: 'confirmation',
      },
    },
  },
  [Scenes.MY_CULTIVATION]: {
    name: 'My cultivation',
    path: '/my-cultivation',
    routes: {
      home: {
        name: 'Home',
        path: '/my-cultivation/home',
        route: 'home',
      },
      zone: {
        name: 'Zone',
        path: '/my-cultivation/zone/:id',
        buildPath: (id: string) => `/my-cultivation/zone/${id}`,
        route: '/zone/:id',
      },
    },
  },
  [Scenes.PROFILE]: {
    name: 'Profile',
    path: '/profile',
    routes: {
      emailVerification: {
        path: '/profile/email-verification',
        name: 'Verify email address thru TOTP code',
        route: 'email-verification',
      },
      notifications: {
        path: '/profile/notifications',
        name: 'Notifications',
        route: 'notifications',
      },
      personalInfo: {
        path: '/profile/info',
        name: 'Personal info',
        route: 'info',
      },
      phoneVerification: {
        path: '/profile/phone-number-verification',
        name: 'Verify phone number thru TOTP code',
        route: 'phone-number-verification',
      },
      preferences: {
        path: '/profile/preferences',
        name: 'User preferences',
        route: 'preferences',
      },
      security: {
        path: '/profile/security',
        name: 'Security',
        route: 'security',
      },
    },
  },
  [Scenes.SETUP]: {
    name: 'Setup',
    path: '/setup',
    routes: {
      organization: {
        name: 'New organization',
        path: '/setup/organization',
        route: 'organization',
      },
    },
  },
  [Scenes.STOCK]: {
    name: 'Stock',
    path: '/stock',
    routes: {
      inventory: {
        name: 'inventory',
        path: '/stock/inventory',
        route: 'inventory',
      },
      lotEditor: {
        name: 'Lot',
        path: '/stock/lot/:id',
        buildPath: (id: string) => `/stock/lot/${id}`,
        route: '/lot/:id',
      },
      mainboardEditor: {
        name: 'Mainboard',
        path: '/stock/main/:id',
        buildPath: (id: string) => `/stock/main/${id}`,
        route: '/main/:id',
      },
    },
  },
  [Scenes.SUPPORT]: {
    name: 'Support',
    path: '/support',
    routes: {
      home: {
        name: 'Home',
        path: '/support/home',
        route: 'home',
      },
      issue: {
        name: 'Issue',
        path: '/support/issue/:id',
        route: '/issue/:id',
        buildPath: (id: string) => `/support/issue/${id}`,
      },
    },
  },
  [Scenes.UNAUTH]: {
    name: 'Unauth scene',
    path: '/unauth',
    routes: {
      forgotPassword: {
        path: '/unauth/forgot-password',
        name: 'Forgot password',
        route: 'forgot-password',
      },
      mfa: {
        path: '/unauth/multi-factor-authentication',
        name: 'Multi-factor Authentication',
        route: 'multi-factor-authentication',
      },
      passwordRestore: {
        path: '/unauth/password-restore',
        name: 'Password restore',
        route: 'password-restore',
      },
      signIn: {
        path: '/unauth/sign-in',
        name: 'Log In',
        route: 'sign-in',
      },
      signUp: {
        path: '/unauth/sign-up',
        name: 'Sign Up',
        route: 'sign-up',
      },
      signupConfirmation: {
        path: '/unauth/signup-confirmation',
        name: 'Sign Up Confirmation',
        route: 'signup-confirmation',
      },
    },
  },
  [Scenes.UTILITIES]: {
    name: 'Utilities',
    path: '/utilities',
    routes: {
      tools: {
        name: 'tools',
        path: '/utilities/tools',
        route: 'tools',
      },
    },
  },
});
