import React, { Suspense } from 'react';
import { AbsoluteCenter, BoxProps, Container, Flex, Spacer } from '@chakra-ui/react';
import { Header } from './Header';
import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { Loading } from '..';

interface AuthTemplateProps extends BoxProps {
  maxW?: string | number;
}

export const FullWindow: React.FC<AuthTemplateProps> = () => {
  return (
    <Flex direction="column" minH="100vh" bg={'appBg'}>
      <Header
        style={{
          position: 'sticky',
          width: '100%',
          top: 0,
          left: 0,
        }}
      />
      <Container maxW={'container.xl'}>
        <Suspense
          fallback={
            <AbsoluteCenter>
              <Loading />
            </AbsoluteCenter>
          }
        >
          <Outlet />
        </Suspense>
      </Container>

      <Spacer />

      <Footer
        mt={2}
        style={{
          width: '100%',
          zIndex: 2,
        }}
      />
    </Flex>
  );
};

export const SmallWindow: React.FC = () => {
  return (
    <Flex direction="column" minH="100vh" bg={'appBg'}>
      <Container maxW={500} mx="auto" pt={10}>
        <Suspense
          fallback={
            <AbsoluteCenter>
              <Loading />
            </AbsoluteCenter>
          }
        >
          <Outlet />
        </Suspense>
      </Container>
      <Spacer />
      <Footer
        style={{
          width: '100%',
          zIndex: 2,
        }}
      />
    </Flex>
  );
};
