import { createTRPCReact, type inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { AppRouter, APIInputs as APIInputsType, APIOutputs as APIOutputType } from '../../../server/src/api';

export const API = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
});

export type API = AppRouter;
export type APIOptions = inferReactQueryProcedureOptions<AppRouter>;
export type APIInputs = APIInputsType;
export type APIOutputs = APIOutputType;
