import React from 'react';

export const AsyncAnalytics = React.lazy(() => import('../scenes/Analytics/Index'));
export const AsyncOrganization = React.lazy(() => import('../scenes/Organization/Index'));
export const AsyncAutomations = React.lazy(() => import('../scenes/Automations/Index'));
export const AsyncBackoffice = React.lazy(() => import('../scenes/Backoffice/Index'));
export const AsyncDevices = React.lazy(() => import('../scenes/Devices/Index'));
export const AsyncCultivationLogs = React.lazy(() => import('../scenes/CultivationLogs/Index'));
export const AsyncInvite = React.lazy(() => import('../scenes/Invite/Index'));
export const AsyncMyCultivation = React.lazy(() => import('../scenes/MyCultivation/Index'));
export const AsyncProfile = React.lazy(() => import('../scenes/Account/Index'));
export const AsyncSetup = React.lazy(() => import('../scenes/Setup/Index'));
export const AsyncStock = React.lazy(() => import('../scenes/Backoffice/Stock/Index'));
export const AsyncSupport = React.lazy(() => import('../scenes/Support/Index'));
export const AsyncUnauth = React.lazy(() => import('../scenes/Unauth/Index'));
