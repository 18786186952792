export enum SignalLevel {
  NO_SIGNAL = 'no_signal',
  LOW = 'low',
  MEDIUM = 'medium',
  FULL = 'full',
}

export const getWifiSignalLevel = (signal: number | null): SignalLevel => {
  if (signal === null) {
    return SignalLevel.NO_SIGNAL;
  }
  if (signal >= -50) {
    return SignalLevel.FULL;
  } else if (signal >= -70) {
    return SignalLevel.MEDIUM;
  } else {
    return SignalLevel.LOW;
  }
};

export const isIddLocationState = (state: unknown): state is { idd: string } => {
  return typeof state === 'object' && state !== null && 'idd' in state;
};
